<template>
  <component :is="componentTag"
    :class="`EJButton EJButton__${size} EJButton__${variant} EJButton__${type} ${disabled && 'EJButton__disabled'}`"
    @click="handleClick"
    :href="href"
    :target="target"
  >
    <slot name="icon-before" />
    <slot />
    <slot name="icon-after" />
  </component>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { useSlots, computed } from 'vue'
// import { string } from 'zod'

const props = defineProps({
  size: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    default: 'medium'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  variant: {
    type: String as PropType<'button' | 'icon' | 'link'>,
    required: false,
    default: 'button'
  },
  type: {
    type: String as PropType<'primary' | 'secondary' | 'deemphasize'>,
    default: 'primary'
  },
  // Turns the button into a link
  href: {
    type: String,
    default: undefined,
    required: false,
  },
  target: {
    type: String,
    default: undefined,
    required: false,
  }
})

const emit = defineEmits<{
  (type: 'click', e: MouseEvent): void
}>()

const slots = useSlots()

const handleClick = (e: MouseEvent) => {
  if (!props.disabled) emit('click', e)
}

const componentTag = computed(() => props.href ? 'a' : 'button');
</script>

<style lang="scss">
@import "../../styles/main.scss";
.EJButton {
  @apply text-lg flex items-center justify-center flex-nowrap;
  font-size: 16px;

  &__disabled {
    @apply cursor-default;
  }

  &__button {
    @apply outline outline-2 focus-within:outline-2 uppercase focus-within:z-10;

    &.EJButton__small {
      @apply px-2 py-1;
    }

    &.EJButton__medium {
      @apply px-4 py-2 min-w-[180px] min-h-[56px] md:min-w-[212px] md:min-h-[62px];
    }

    &.EJButton__large {
      @apply px-8 py-2 min-w-[250px] min-h-[60px] md:min-w-[300px] md:min-h-[66px] text-3xl md:text-4xl capitalize;
    }
  }

  &__icon {
    &.EJButton__small {
      @apply w-8 h-8;
    }

    &.EJButton__medium {
      @apply w-16 h-16;
    }

    &.EJButton__large {
      @apply w-16 h-16;
    }
  }

  &.EJButton__link {
    @apply inline-flex items-baseline;
  }

  &__primary {
    &.EJButton__button,
    &.EJButton__icon {
      @apply gap-4 bg-black text-white outline outline-2 outline-lime-500 stroke-white;
      @apply hover:bg-lime-950 hover:outline-lime-200;
      @apply active:bg-lime-500 active:outline-lime-500 active:text-black active:stroke-black;

      &.EJButton__disabled {
        @apply bg-gray-800 outline-gray-600 text-gray-300 dark:bg-gray-200 dark:outline-gray-400 dark:text-gray-700;
      }
    }

    &.EJButton__link {
      @apply ejx-text underline font-medium text-lime-500 hover:text-lime-700 group-[.is-inverted]:text-lime-300 dark:text-lime-300;

      &.EJButton__disabled {
        @apply text-gray-600 group-[.is-inverted]:text-gray-400 dark:text-gray-200;
      }
    }
  }

  &__secondary {
    &.EJButton__button,
    &.EJButton__icon {
      @apply outline outline-2 bg-black text-white outline-white stroke-white;
      @apply hover:bg-gray-800 hover:outline-gray-200;
      @apply active:bg-white active:text-black active:stroke-black;

      &.EJButton__disabled {
        @apply bg-gray-200 outline-gray-500 text-gray-700 dark:bg-gray-700 dark:outline-gray-400 dark:text-gray-200;
      }
    }

    &.EJButton__link {
      @apply ejx-text underline font-medium;

      &.EJButton__disabled {
        @apply text-gray-600 group-[.is-inverted]:text-gray-400 dark:text-gray-200;
      }
    }
  }
  &__deemphasize {
    &.EJButton__button,
    &.EJButton__icon {
      @apply outline outline-1 text-gray-400 stroke-gray-200 outline-gray-400 bg-none;
      @apply hover:stroke-gray-100 hover:outline-gray-300;
      @apply active:text-black active:stroke-white active:outline-gray-100;

      &.EJButton__disabled {
        @apply bg-gray-200 outline-gray-500 text-gray-700 dark:bg-gray-700 dark:outline-gray-400 dark:text-gray-200;
      }
    }

    &.EJButton__link {
      @apply ejx-text underline font-medium;

      &.EJButton__disabled {
        @apply text-gray-600 group-[.is-inverted]:text-gray-400 dark:text-gray-200;
      }
    }
  }
}
</style>
