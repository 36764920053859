<template>
  <div ref="containerEl" class="LazyVideo relative w-full h-full select-none">
    <img v-if="placeholderImg" :src="placeholderImg.src" :class="{
      'top-0 left-0 w-full h-full object-cover absolute': true,
      'invisible': showVideo,
    }" />
    <video 
      ref="videoEl"
      :src="src" 
      :autoplay="autoplay"
      :preload="preload"
      :loop="loop"
      :muted="mute"
      :playsinline="playsinline"
      @canplay="handleCanPlay"
      @play="handlePlay"
      @progress="handleProgress"
      :class="{
      'top-0 left-0 w-full h-full object-cover absolute': true,
      'invisible': !showVideo,
    }">
      <source v-for="source in sources" :key="source.src" :src="source.src" :type="source.type">
    </video>
  </div>
</template>

<script setup lang="ts">
import { type MediaHTMLAttributes, type PropType, type Ref, onMounted, ref } from 'vue';

const props = defineProps({
  placeholderImg: {
    type: Object,
    required: false,
    default: undefined,
  },
  src: {
    type: String,
    required: false,
    default: undefined,
  },
  sources: {
    type: Object as PropType<{ src: string, type: string }[]>,
    required: false,
    default: [],
  },
  mute: {
    type: Boolean,
    required: false,
    default: true,
  },
  loop: {
    type: Boolean,
    required: false,
    default: false,
  },
  autoplay: {
    type: Boolean,
    required: false,
    default: false,
  },
  preload: {
    type: String as PropType<MediaHTMLAttributes['preload']>,
    required: false,
    default: 'auto',
  },
  playsinline: {
    type: Boolean,
    required: false,
    default: false,
  },
  videoRef: {
    type: Object as PropType<Ref<HTMLVideoElement | undefined>>,
    required: false,
    default: undefined,
  }
})

const containerEl = ref<HTMLDivElement>();
const videoEl = ref<HTMLVideoElement|undefined>();
const showVideo = ref(false);

onMounted(() => {
  if (videoEl.value && videoEl.value.paused === false) {
    handlePlay();
  }
})
const handleCanPlay = () => {
  if (props.autoplay && videoEl.value) videoEl.value.play();
}
const handlePlay = () => {
  showVideo.value = true;
}

const handleProgress = () => {
  if (!showVideo.value) showVideo.value = true;
}

const play = () => {
  if (videoEl.value) {
    videoEl.value.play();
  }
}

const pause = () => {
  if (videoEl.value) {
    videoEl.value.pause();
  }
}

const paused = () => {
  return videoEl.value?.paused;
}

defineExpose({
  play,
  pause,
  paused,
})

</script>
