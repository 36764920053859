
/**
 * DocumentScrollManager is a singleton that locks / unlocks the scroll.  
 * Can be used by vue components such as EJPopup to disable scrolling of
 * the document body.
 */
export class DocumentScrollManager {
  static lockNumber = 0;
  static instance: DocumentScrollManager;
  static getInstance() {
    if (!DocumentScrollManager.instance) {
      DocumentScrollManager.instance = new DocumentScrollManager();
    }

    return DocumentScrollManager.instance;
  }


  private locks: Set<number>;

  constructor() {
    this.locks = new Set();
  }

  /**
   * Registers a component that is locking the document scroll.
   * @returns 
   */
  lock() {
    const id = DocumentScrollManager.lockNumber++;
    this.locks.add(id);
    this.recalculateScroll();
    return  {
      id,
      unlock: () => {
        this.unlock(id);
      }
    };
  }

  unlock(lockId: number) {
    this.locks.delete(lockId);
    this.recalculateScroll();
  }

  /** Recalculates whether to lock / unlock scrolling on document body */
  private recalculateScroll() {
    if (this.locks.size > 0) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }
}
