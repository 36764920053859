<template>
  <iframe
    ref="iframeEl"
    class="w-full h-full"
    allow="xr-spatial-tracking; clipboard-write; fullscreen;"
    :src="iframeSrc"
  ></iframe>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { PropType } from 'vue'
import { useEventListener } from '@vueuse/core';
import { computed } from '@vue/reactivity';
import { type Cube } from '../../data';

const props = defineProps({
  currentArtwork: {
    type: Object as PropType<Cube>,
    required: false,
    default: undefined,
  }
})

const baseUrl = props.currentArtwork && props.currentArtwork.minted ? 'https://eyejack.xyz' : 'https://demos.eyejack.xyz';

const iframeSrc = computed(() => {
  let url: string | URL | undefined = undefined;
    if (props.currentArtwork && props.currentArtwork.minted) {
      url = new URL(`${baseUrl}/collections/KT1H6jXeZppUf6kVp1JxBdFoCSjHb4jJZC6b/${props.currentArtwork.id}`) 
    } else {
      url = new URL(`${baseUrl}/index-gallery`)
      if (props.currentArtwork) url.searchParams.append('a', props.currentArtwork.artworkId)
    }

  return url && url.toString();
})
// Iframe contols
const iframeEl = ref<HTMLIFrameElement>();
onMounted(() => { // Client only
  useEventListener(window, 'message', (event) => {
      if (!iframeEl.value) return;
      if (event.data.type === '@eyejack/ejx-launcher-button:redirect') {
        window.open(event.data.launchUrl, '_blank');
      } else if (event.data.type === '@eyejack/ejx-launcher-button:started') {
        iframeEl.value.classList.add('ejx-canvas')
      } else if (event.data.type === '@eyejack/ejx-launcher-button:stopped') {
        iframeEl.value.classList.remove('ejx-canvas')
      }
  })
})
  
</script>
