<template>
  <section class="relative">
    <LazyVideo ref="videoEl" class="md:min-h-screen" style="min-height: calc(var(--ih) * 100);" :placeholderImg="placeholder" :sources="videoSources" :mute="isMuted" :loop="true" :autoplay="true" />
    <div class="absolute inset-0 w-full h-full bg-black bg-opacity-60"></div>


    <h1 class="ejx-h1 text-white text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      A&nbsp;new&nbsp;Canvas for&nbsp;XR&nbsp;Experiences
    </h1>

    <div class="flex absolute bottom-8 left-1/2 gap-4 items-center -translate-x-1/2">
      <EJButton @click="showIframe" class="whitespace-nowrap">Experience&nbsp;Cubes</EJButton>
      <EJButton class="absolute left-[calc(100%+20px)]" variant="icon" type="deemphasize" size="small" @click="isMuted = !isMuted">
        <svg v-if="isMuted" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 13.9998L20.0001 12M20.0001 12L18 10M20.0001 12L18 14M20.0001 12L22 10.0002M4 16H5C5.55228 16 6 15.5523 6 15V9C6 8.44772 5.55228 8 5 8H4C2.89543 8 2 8.89543 2 10V14C2 15.1046 2.89543 16 4 16ZM6.55279 7.72361L12.5528 4.72361C13.2177 4.39116 14 4.87465 14 5.61804V18.382C14 19.1253 13.2177 19.6088 12.5528 19.2764L6.55279 16.2764C6.214 16.107 6 15.7607 6 15.382V8.61804C6 8.23926 6.214 7.893 6.55279 7.72361Z" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 9.7639C18.6137 10.3132 19 11.1115 19 12C19 12.8885 18.6137 13.6868 18 14.2361M20 7.52757C21.2275 8.62622 22 10.2228 22 11.9998C22 13.7768 21.2275 15.3734 20 16.472M4 16H5C5.55228 16 6 15.5523 6 15V8.99999C6 8.4477 5.55228 7.99999 5 7.99999H4C2.89543 7.99999 2 8.89542 2 9.99999V14C2 15.1046 2.89543 16 4 16ZM6.55279 7.72359L12.5528 4.72359C13.2177 4.39114 14 4.87464 14 5.61802V18.382C14 19.1253 13.2177 19.6088 12.5528 19.2764L6.55279 16.2764C6.214 16.107 6 15.7607 6 15.382V8.61802C6 8.23925 6.214 7.89299 6.55279 7.72359Z" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </EJButton>
    </div>

    <EJPopup v-model:open="isIframeOpen" innerClass="w-[calc(100vw-30px)] h-[calc(var(--ih)*100-30px)]">
      <CubesDemoIframe v-if="isIframeOpen" />
    </EJPopup>
  </section>
</template>

<script setup lang="ts">
/** 
* Vue island for the site header.  Includes video + launch iframe.
*/

import { ref, shallowRef, watchEffect, onMounted } from 'vue';
import LazyVideo from './generics/LazyVideo.vue'
import EJButton from './generics/EJButton.vue'
import EJPopup from './generics/EJPopup.vue'
import CubesDemoIframe from './generics/CubesDemoIframe.vue'
import { useEventListener } from '@vueuse/core';
defineProps({
  placeholder: {
    type: Object,
    required: true,
  },
  videoSources: {
    type: Object as PropType<{src: string, type: string}[]>,
    required: true,
  }
})

const showIframe = () => {
  window.location.hash = '#cubes';
}

const isMuted = ref(true);
const isIframeOpen = ref(false);
// Will load the artwork defined by the window hash
onMounted(() => {
  const showArtworkFromHash = () => {
    if (window.location.hash === '#cubes') {
      isIframeOpen.value = true;
      return;
    }
    isIframeOpen.value = false;
  }
  useEventListener(window, 'hashchange', showArtworkFromHash); 
  showArtworkFromHash();
  watchEffect(() => {
    if (!isIframeOpen.value && window.location.hash === '#cubes') {
      history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  });
})

const videoEl = shallowRef<typeof LazyVideo>();
watchEffect(() => {
  if (videoEl.value) {
    if (isIframeOpen.value) {
      videoEl.value.pause();
    } else {
      videoEl.value.play();
    }
  }
})
</script>
