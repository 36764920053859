<template>
  <Teleport v-if="open" to="body">
    <div class="z-[5000] fixed inset-0 w-full h-full h-max-[-webkit-fill-available] bg-black bg-opacity-50">
      <div 
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-black p-4 outline outline-gray-500 outline-solid"
        :class="innerClass ? innerClass : ''"
      >
        <EJButton class="absolute right-4 top-4" size="small" variant="icon" type="deemphasize" @click="emit('update:open', false)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18M18 18L6 6.00001" stroke-width="2" stroke-linecap="round"/>
        </svg>

        </EJButton>
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onMounted, watchEffect } from 'vue';
import EJButton from './EJButton.vue';
import { DocumentScrollManager } from '../../utils/DocumentScrollManager';
import { useMounted } from '@vueuse/core';
const props = defineProps({
  open: {
    type: Boolean,
    default: true,
    required: false,
  },
  innerClass: {
    type: String,
    required: false,
    default: undefined,
  }
})
const emit = defineEmits<{
  'update:open': [isOpen: boolean],
}>();

// Locking / unlocking document scroll
const mounted = useMounted();
let unlockScrollId: number | undefined;
watchEffect(() => {
  if (mounted.value && props.open) {
    const { id } = DocumentScrollManager.getInstance().lock();
    unlockScrollId = id;
  } else if (unlockScrollId !== undefined) {
    DocumentScrollManager.getInstance().unlock(unlockScrollId);
  }
})
</script>
